.profile {
  margin-top: 40px;
}

.profile__container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}

.profile__photo {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
}

.profile__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile__edit-icon{
  position: absolute;
  top: 40%;
  left: 40%;
  opacity: 0;
}

.profile__photo:hover .profile__image {
  opacity: 0.5;
}

.profile__photo:hover .profile__edit-icon {
  opacity: 1;
  cursor: pointer;
}
.profile__info {
  align-self: center;
  width: 35vw;
}

.profile__name {
  display: flex;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  margin: 0;
  margin-right: 18px;

  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile__button-edit {
  max-width: 24px;
  max-height: 24px;
  background-color: #000;
  border: 1px solid #fff;
  align-self: center;
}

.profile__button-edit:hover {
  opacity: 50%;
  cursor: pointer;
}

.profile__about {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
  margin-top: 16px;

  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile__add {
  width: 150px;
  height: 50px;
  align-self: center;
}

.profile__button-add {
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 50px;

  background-color: #000;
  border: 1px solid #fff;

  color: #fff;
  font-size: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__button-add:hover {
  opacity: 50%;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .profile {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .profile__photo {
    margin-right: 0px;
  }

  .profile__info {
    margin: 0px;
  }

  .profile__title {
    max-width: 300px;
  }


}

@media screen and (max-width: 320px) {
  .profile {
    margin-top: 42px;
  }


  .profile__container {
    max-width: 282px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile__info {
    align-self: center;
    margin: 0px;
  }

  .profile__photo{
    margin-right: 0px;
  }


  .profile__title {
    font-size: 22px;
    line-height: 27px;
    margin: 0px;
    margin-right: 10px;
    max-width: 282px;
  }

  .profile__about {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 36px;
    text-align: center;
    max-width: 282px;
  }

  .profile__add {
    width: 282px;
    height: 50px;
  }

  .profile__button-add {
    max-width: 282px;
    max-height: 50px;
    font-size: 30px;
  }
  .profile__name{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    
  }
}
