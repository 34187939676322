.confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.confirm_opened {
  display: flex;
}

.confirm__form {
  width: 430px;

  max-height: 181px;
  height: 100%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  border-radius: 10px;
}

.confirm__close-button {
  position: absolute;
  top: -30px;
  right: -30px;
}

.confirm__close-button:hover {
  opacity: 50%;
  cursor: pointer;
}

.confirm__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  width: 100%;
  text-align: left;
  margin-top: 34px;
  margin-bottom: 38px;
  margin-left: 66px;
}


.confirm__button {
  width: 358px;
  height: 50px;
  margin: 20px auto;
  display: block;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top:0px;
  margin-bottom: 36px;
}

.confirm__button:hover {
  opacity: 50%;
}

.confirm__button:disabled {
  background-color: #fff;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.confirm__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Clase Boton Desactivado*/
.confirm__button_disabled {
  background-color: #fff;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.confirm__input_type_error {
  border-bottom: 1px solid #e10e0e;
}

.confirm__error_visible {
  display: flex;
}
@media screen and (max-width: 320px) {
  .confirm__form {
    width: 282px;
    height: 400px;
  }

  .confirm__close-button {
    position: absolute;
    top: -40px;
    right: 0;
  }

  .confirm__title {
    font-size: 18px;
    line-height: 22px;
    margin-left: 50px;
    margin-bottom: 24px;
  }

  .confirm__button {
    width: 238px;
    height: 46px;
    margin: 20px auto;
    display: block;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;

  }

}
