.header {
  display: flex;
}

.header__container {
  border-bottom: 1px solid #545454;
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.header__logo {
  margin-top: 45px;
  margin-bottom: 41px;
}


/*AA*/

.header__login{
  display: flex;
}

.header__email{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-right: 23px;
  margin-top: 45px;
}

.header__signout{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */
  text-align: right;
  margin-top: 45px;
  color: #A9A9A9;
}

.header__signout:hover{
  cursor: pointer;
  color: white;
}


.header__menu{
  margin-right: 30px;
  margin-top: 30px;
  width: 24px;
  height: 18px;
  display: none;
}

.header__menu-close{
  margin-right: 30px;
  margin-top: 30px;
  width: 24px;
  height: 24px;
  display: none;
}

.header__mobile-login{
  display: none;
}

.header__mobile-email{
  display: none;
}

.header__mobile-signout{
  display: none;
}

.header__mobile-signout:hover{
  cursor: pointer;
  color: white;
}

@media screen and (max-width: 800px) {
  .header {
    padding: 0px;
    margin: 0 auto;
    max-width: 90%;
  }
}

@media screen and (max-width: 320px) {
  .header {
    padding: 0px;
    margin: 0 auto;
    max-width: 100%;
    flex-direction: column;
  }

  .header__container {
    max-width: 320px;
  }

  .header__logo {
    padding-left: 27px;
    margin-top: 28px;
    margin-bottom: 32px;
    width: 126px;
    height: 26px;
  }



  .header__login{
    display: none;
  }
  
  .header__email{
    display: none;
  }
  
  .header__signout{
    display: none;
  }
 
  .header__menu{
    display: flex;
  }
  .header__menu:hover{
    cursor: pointer;
    opacity: 0.5;
  }

  .header__menu-close{
    display: flex;
  }

  .header__menu-close:hover{
    cursor: pointer;
    opacity: 0.5;
  }

  .header__mobile-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    border-bottom: 1px solid #545454;;
  }
  
  .header__mobile-email{
    display: flex;
    margin: 0px;
    margin-top: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .header__mobile-signout{
    display: flex;
    margin: 0px;
    margin-top: 18px;
    margin-bottom: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #A9A9A9;
    
  }

}




