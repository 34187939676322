.login{

}

.login__header {
    display: flex;
}

.login__container-header{
    border-bottom: 1px solid #545454;
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.login__logo {
    margin-top: 45px;
    margin-bottom: 41px;
}

.login__register{
    margin: 0px;
    margin-top: 45px;
    margin-bottom: 41px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    
}

.login__register:hover{
    cursor: pointer;
    opacity: 0.5;
}

.login__container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__form{
    display: flex;
    flex-direction: column;
    width: 358px;
}

.login__title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    margin-top: 60px;
    margin-bottom: 50px;
}

.login__label{
}

.login__input-email{
    border: none;
    background-color: black;
    height: 25px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
    margin-bottom: 30px;
    color: #fff;
}

.login__input-email::placeholder{
    color: #CCCCCC;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.login__input-password{
    border: none;
    background-color: black;
    height: 25px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
    margin-bottom: 216px;
    color: #fff;
}

.login__input-password::placeholder{
    color: #CCCCCC;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.login__button{
    height: 50px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    
}

.login__button:hover{
    opacity: 0.5;
    cursor: pointer;
}

.login__footer{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.login__footer:hover{
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (max-width: 800px) {
    .login__header {
      padding: 0px;
      margin: 0 auto;
      max-width: 90%;
    }
  }
  
  @media screen and (max-width: 320px) {
    .login__header {
      padding: 0px;
      margin: 0 auto;
      max-width: 100%;
    }
  
    .login__container-header{
      max-width: 320px;
    }
  
    .login__logo {
      height: 24px;
      margin: 0px;
      margin-top: 28px;
      margin-bottom: 32px;
      width: 126px;
      padding-left: 30px;
    }

    .login__register{
      margin: 0px;
      margin-top: 28px;
      padding-right: 30px;

      font-size: 14px;
      line-height: 17px;
    }

    .login__form{
        width: 260px;
    }

    .login__input-password {
        margin-bottom: 173px;
    }
    
    .login__title {
        margin-top: 40px;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    .login__footer{
        font-size: 12px;
        line-height: 15px;
        margin-top: 20px;
    }
    
}
  
