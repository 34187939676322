.elements {
  display: grid;
  grid-template-columns: 282px 282px 282px;
  justify-content: center;
  gap: 20px 17px;
  margin-top: 50px;
}

@media screen and (max-width: 800px) {
  .elements {
    grid-template-columns: 282px 282px;
  }
}


@media screen and (max-width: 320px) {
  .elements {
    grid-template-columns: 282px;
  }
}
